var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（キャスト名検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.casts,"search":_vm.search,"item-key":"id","mobile-breakpoint":0,"items-per-page":-1,"footer-props":{
      'items-per-page-options': [50, 100, -1],
      'items-per-page-text': '表示件数',
    }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",on:{"click":function($event){return _vm.editItem(item.id)}}},[_vm._v("mdi-pencil")])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"pa-1"},[(item.image)?_c('v-img',{attrs:{"width":"50","src":_vm.setting.cdn + item.image}}):_c('v-img',{attrs:{"width":"50","src":require("@/assets/images/no_image.jpg")}})],1)]}},{key:"item.enable",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"pa-1"},[(item.is_enable)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" 公開中 ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","small":""}},[_vm._v(" 非公開 ")])],1)]}},{key:"item.profile",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"profile_span"},[_vm._v(_vm._s(item.profile_1)),_c('br'),_vm._v(_vm._s(item.profile_2))])]}},{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteCast(item.id, item.name)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_vm._v(" キャストが登録されていません ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }