<template>
  <v-card elevation="0">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="（キャスト名検索）"
        single-line
        hide-details
        clearable
        dense
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="casts"
      :search="search"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      }"
    >
      <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <!-- 編集 -->
      <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
        <v-icon class="pa-1" @click="editItem(item.id)">mdi-pencil</v-icon>
      </template>
      <!-- 名前 -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <!-- 画像 -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.image="{ item }">
        <div class="pa-1">
          <v-img
            v-if="item.image"
            width="50"
            :src="setting.cdn + item.image"
          ></v-img>
          <v-img v-else width="50" src="@/assets/images/no_image.jpg"></v-img>
        </div>
      </template>
      <!-- 公開 -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.enable="{ item }">
        <div class="pa-1">
          <v-chip
            v-if="item.is_enable"
            class="ma-2"
            color="primary"
            label
            small
          >
            公開中
          </v-chip>
          <v-chip v-else class="ma-2" label small> 非公開 </v-chip>
        </div>
      </template>
      <!-- プロフィール -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.profile="{ item }">
        <span class="profile_span"
          >{{ item.profile_1 }}<br />{{ item.profile_2 }}</span
        >
      </template>
      <!-- 削除 -->
      <template v-slot:item.delete="{ item }"> <!-- eslint-disable-line -->
        <v-icon @click="deleteCast(item.id, item.name)">mdi-delete</v-icon>
      </template>
      <template v-slot:no-data> キャストが登録されていません </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { reactive, toRefs, defineComponent, set } from "@vue/composition-api";
import store from "@/store/index.js";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  setup(_, ctx) {
    const { router } = useRouter();
    const castsRepository = repositoryFactory.get("casts");
    const state = reactive({
      search: "",
      list: 0,
      casts: [],
      grid_cols: 11,
      grid_width: 127,
      breakpoint: "",
      selected: [],
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "画像",
          value: "image",
          sortable: false,
          align: "center",
          width: 66,
        },
        {
          text: "キャスト",
          value: "name",
          sortable: false,
          class: "td_name",
        },
        { text: "公開/非公開", value: "enable", sortable: false, width: 110 },
        {
          text: "プロフィール",
          value: "profile",
          sortable: false,
          class: "td_profile",
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // // 店舗チェック falseの場合ログアウトになる
      // const currentShop = store.getters["shops/currentShop"];
      // if (!(await utilFunc.checkShop(currentShop.id, router))) return;

      // システム内の登録キャストをstoreから取得する
      state.casts.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await castsRepository
        .list_profile(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.casts.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:cast/clistTable/index.vue/init castsRepository.list (" +
            error +
            ")"
          );
        });

      Object.keys(state.casts).forEach(function (key) {
        const common_shop_site = [];
        // const relating_sites = [];
        Object.keys(state.casts[key].prop).forEach(function (pkey) {
          common_shop_site.push(state.casts[key].prop[pkey]);
        });
        const p_age = common_shop_site.find((r) => r.key == "cast__basic__age");
        const p_tall = common_shop_site.find(
          (r) => r.key == "cast__basic__tall"
        );
        const text_age = "年齢:" + (!p_age ? "__" : p_age["value"] ?? "__");
        const text_tall = " 身長:" + (!p_tall ? "__" : p_tall["value"] ?? "__");
        const profile_1 = text_age + text_tall;

        const p_bust = common_shop_site.find(
          (r) => r.key == "cast__basic__bust"
        );
        const p_cup = common_shop_site.find((r) => r.key == "cast__basic__cup");
        const p_waist = common_shop_site.find(
          (r) => r.key == "cast__basic__waist"
        );
        const p_hip = common_shop_site.find((r) => r.key == "cast__basic__hip");
        const text_bust = "B:" + (!p_bust ? "__" : p_bust["value"] ?? "__");
        const text_cup =
          "(" + (!p_cup || p_cup == null ? "__" : p_cup["value"] ?? "__") + ")";
        const text_waist = " W:" + (!p_waist ? "__" : p_waist["value"] ?? "__");
        const text_hip = " H:" + (!p_hip ? "__" : p_hip["value"] ?? "__");
        const profile_2 = text_bust + text_cup + text_waist + text_hip;

        set(state.casts[key], "profile_1", profile_1);
        set(state.casts[key], "profile_2", profile_2);
      });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 初期化する
    init();

    // キャスト編集ボタンが押されたとき（未使用）
    const editCast = (id, name) => {
      alert(name + " " + id);
    };

    // キャスト削除ボタンが押されたとき
    const deleteCast = async (cast_id, name) => {
      if (
        !window.confirm(
          "キャスト「" +
            name +
            "」を削除してよろしいですか？" +
            "\n※媒体側のデータは残ります。当システムからのみ削除します。"
        )
      ) {
        return false;
      }

      // 削除処理
      await castsRepository
        .delete(cast_id)
        .then((response) => {
          if (response.data) {
            // データ更新OK
            const del_cast_index = state.casts.findIndex(
              ({ id }) => id === cast_id
            );
            state.casts.splice(del_cast_index, 1);
          }
        })
        .catch((error) => {
          throw (
            "ERROR:Cast/List/index.vue/deleteCast CastsRepository.delete (" +
            error +
            ")"
          );
        });

      return true;
    };

    // 店舗媒体コンテンツの設定変更
    const editItem = async (cast_id) => {
      await router.push({
        name: "cast-edit",
        params: {
          cast_id: cast_id,
        },
      });
    };

    return {
      setting,
      ...toRefs(state),
      editCast,
      deleteCast,
      editItem,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .mini label {
  font-size: 0.75em;
}

::v-deep .td_name {
  min-width: 100px;
  width: 200px;
}
::v-deep .td_profile {
  min-width: 120px;
}
.profile_span {
  font-size: 0.75em;
}
::v-deep .td_shop_site {
  min-width: 200px;
}
.shop_site_span {
  font-size: 0.75em;
  background-color: #fafafa;
  word-break: break-all;
}
</style>
